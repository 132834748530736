.projects {
	.table-responsive, .map {
		height: 100vh !important;
		@include media-breakpoint-up(md) {
			height: 35vh !important;
		}
	}
}
/*********************************************************
	User Profile / Profile Icon Crop Modal 	
*********************************************************/
.avatarCropModal {
	&>div {
		width: inherit !important;
		&>div {
			width: inherit !important;
			&>div {
				overflow: auto;
			}	
		}
	}
}

.avatar-edit-btn {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.myaccount-avatar-container {
	width: 90px;
	height: 90px;
}

.myaccount-avatar-box {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
/*********************************************************
	Google News
*********************************************************/
.swiper-container {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
  height: auto;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-container {
	margin-left: auto;
	margin-right: auto;
	// height: 400px;
  .swiper-pagination-bullets {
    bottom: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}

/*********************************************************
	common use
*********************************************************/
.relative {
	position: relative
}
.absolute {
	position: absolute
}
/*********************************************************
	Google Map 
*********************************************************/
.marker {
	width: min-content;
	cursor: pointer;
	transform: scale(1);
	transition: all 0.1s;
	position: relative;
	top: -59px;
	.marker-content {
		width: 70px;
		height: 30px;	
		background: white;
		border: 3px solid;
		border-radius: 4px;		
		text-align: center;	
		overflow: hidden;		
	}
	.marker-arrow {
		position: relative;
		top: -1px;		
		.arrow {
			bottom: 0px;		
			left: -3px;	
			width: 25px;
			height: 25px;
			fill: currentColor;
		}
	}
}

.marker:hover {
	z-index: 1;
	transform: scale(1.5);
}

.marker-site {
	transform-origin: bottom center;
	left: -35px;
    top: -52px;
	text-align: center;	
}

.marker-node {
	transform-origin: bottom left;
}

.tooltipBoundary {
  position: relative;
	height: 300px;
	overflow: hidden;
  .weather-card {
    position: absolute;
    margin-bottom: 0;
    bottom: 1rem;
    left: 1rem;
    color: $white;
    background: rgba(0,0,0,.75);
  }
}
/*********************************************************
	Google Map / node description popup
*********************************************************/
.popup-content {
	margin: auto;
	background: #292D3E;
	width: 50%;
	padding: 5px;
	color: #FFF;
}
.popup-arrow {
	color: #292D3E;
}
[role='tooltip'].popup-content {
	width: 200px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
  
.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
	background: transparent;
}

// @keyframes anvil {
// 	0% {
// 		transform: scale(1) translateY(0px);
// 		opacity: 0;
// 		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
// 	}
// 	1% {
// 		transform: scale(0.96) translateY(10px);
// 		opacity: 0;
// 		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
// 	}
// 	100% {
// 		transform: scale(1) translateY(0px);
// 		opacity: 1;
// 		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
// 	}
// }
//   .popup-content {
// 	-webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
//   }
/*********************************************************
	Sidebar
*********************************************************/

#sidebarnav .sidebar-item div span {
	flex-grow: 1;
}
#sidebarnav .menu-open.sidebar-item div::after {
	transition: 0.2s;
	transform: rotate(180deg);
}
#sidebarnav .sidebar-item div::after {
	transition: 0.2s;
}
#sidebarnav .collapse, .collapsing {
	background: #EEE;
}

/*********************************************************
	react-datepicker
*********************************************************/
.react-datepicker {
	display: inline-flex;
}
.input-animal-dob {
	display: block;
}

@media only screen and (min-width: 576px) {
	/*********************************************************
		User Profile / Profile Icon Crop Modal 
	*********************************************************/
	.myaccount-avatar-container {
		width: 110px;
		height: 110px;
	}
}

@media only screen and (min-width: 992px) {
	/*********************************************************
		User Profile / Profile Icon Crop Modal 
	*********************************************************/	
	.myaccount-avatar-container {
		width: 130px;
		height: 130px;
	}
}

@media only screen and (min-width: 1200px) {
	/*********************************************************
		User Profile / Profile Icon Crop Modal 
	*********************************************************/	
	.myaccount-avatar-container {
		width: 150px;
		height: 150px;
	}
}